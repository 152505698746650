import React from "react";
import { Link } from "react-router-dom";
import { propertyData } from "../data/data";
import {FiHome, FiHeart, FiCamera} from '../assect/icons/vander'

import axios from 'axios'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

import image1 from '../assect/images/bannerastra.png'

export default function Banners(){

    const [propertyData, setPropertyDataData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true)
        axios.get('https://api-v1.gondola-ancol.com/api/public/posts_home').then((response) => {
            setPropertyDataData(response.data.data);
            setLoading(false)
            console.log('response',response.data.data);
        });
      }, []);

    return(
        <>
        <div className="row justify-content-center">
            <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                    <h4 className="title mb-3">Gondola Ancol Partners</h4>
                </div>
            </div>
        </div>

        <div className="row g-4">

        <div>
            <div className="card property border-0 shadow position-relative overflow-hidden rounded-3">
                <div className="property-image position-relative overflow-hidden shadow">
                    <img src={image1} className="img-fluid" alt=""/>
                </div>
            </div>
        </div>

            <div className="col-12 mt-4 pt-2">
            </div>
        </div>
        </>
    )
}