import React from "react";
import { Link } from "react-router-dom";

import category1 from '../assect/images/property/residential.jpg'
import category2 from '../assect/images/property/land.jpg'
import category3 from '../assect/images/property/commercial.jpg'
import category4 from '../assect/images/property/industrial.jpg'
import category5 from '../assect/images/property/investment.jpg'

import axios from 'axios'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export default function Categories(){

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true)
        axios.get('https://api-v1.gondola-ancol.com/api/public/products_home').then((response) => {
            setData(response.data.data);
            setLoading(false)
            console.log('response',response.data.data);
        });
      }, []);

    // const data = [
    //     {
    //         image:category1,
    //         name:'Residential',
    //         listings:'46 Listings'
    //     },
    //     {
    //         image:category2,
    //         name:'Land',
    //         listings:'124 Listings'
    //     },
    //     {
    //         image:category3,
    //         name:'Commercial',
    //         listings:'265 Listings'
    //     },
    //     {
    //         image:category4,
    //         name:'Industrial',
    //         listings:'452 Listings'
    //     },
    //     {
    //         image:category5,
    //         name:'Investment',
    //         listings:'12 Listings'
    //     },
    // ]
    return(
        <>
        <div className="row justify-content-center">
            <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                    <h4 className="title mb-3">Jelajah dan Rekreasi</h4>
                    <p className="text-muted mb-0 text-center">Temukan berbagai promo menarik di Gondola Ancol di sini.</p>
                </div>
            </div>
        </div>

        <div className="row g-4">
            {loading == true ? (
                <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                    <p>
                        <Skeleton height={200} />
                    </p>
                </SkeletonTheme>
            ) : (
                data.slice(0, 6).map((item, index) => {
                    return (
                        <div className="col-lg-4 col-md-6 col-12" key={index}>
                            <Link to={`/product-detail/${item.slug}`}>
                            <div className="card property border-0 shadow position-relative overflow-hidden rounded-3">
                                <div className="property-image position-relative overflow-hidden shadow" style={{ backgroundImage: `url(${item.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <div className="overlay"></div>
                                    <div className="card-body content p-4">
                                            <span className="title fs-5 text-white fw-medium overlay-text">{item.title}</span>
                                        <ul className="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                            <li className="list-inline-item mb-0">
                                                <span className="text-muted"></span>
                                                <p className="fw-medium mb-0"></p>
                                            </li>
                                            <li className="list-inline-item mb-0 text-muted">
                                                <span className="text-muted"></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                    )
                })
            )}

            <div className="col-12 mt-4 pt-2">
                {/* <div className="text-center">
                    <Link to="/grid" className="mt-3 fs-6 text-primary">Lihat Promo lebih <i className="mdi mdi-arrow-right align-middle"></i></Link>
                </div> */}
            </div>
        </div>

        {/* <div className="row row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-1 g-4 mt-0">
            {loading == true?  (
            <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                <p>
                <Skeleton height={200} />
                </p>
            </SkeletonTheme>): (
                data.map((item, index) => {
                    return(
                        <div className="col" key={index}>
                            <div className="categories position-relative overflow-hidden rounded-3 shadow">
                                <img src={item.image} className="img-fluid" alt=""/>

                                <div className="p-3">
                                    <Link to={`/product-detail/${item.slug}`} className="title text-dark fs-5 fw-medium">{item.title}</Link>
                                    <p className="text-muted small mb-0">{item.title}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
            )}
        </div> */}
        </>
    )
}