import React, { useState  } from "react";
import { Link } from "react-router-dom";

import bg3 from "../../assect/images/bg/03.jpg"
import logo from "../../assect/images/logomobile.gif"
import { Oval } from 'react-loader-spinner'

export default function Signup(){
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [nik, setNIK] = useState("");
    const [notiket, setNoTiket] = useState("");
    const [loading, setLoading] = useState(false);

    let handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            let res = await fetch("https://api-gondola.terahype.id/api/public/formregistrasi", {
                method: "POST",
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    nama: name,
                    email: email,
                    no_telp: mobileNumber,
                    nik: nik,
                    no_tiket: notiket
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                setLoading(false)
                alert('Berhasil Menyimpan Data')
                window.location.reload();
            } else {
                setLoading(false)
                alert('Berhasil Menyimpan Data')
                window.location.reload();
            }
        } catch (err) {
            console.log(err);
        }
    };
    
    return(
        <>
        <section className="bg-home zoom-image d-flex align-items-center">
            <div className="bg-overlay image-wrap" style={{backgroundImage:`url(${bg3})`, backgroundPosition:'center'}}></div>
            <div className="bg-overlay bg-gradient-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* <div className="p-4 bg-white rounded-3 shadow-md mx-auto w-100" style={{maxWidth:'400px'}}>
                            {loading && loading == true ?
                            <div className="form-floating mb-2">
                            <Oval
                            visible={loading}
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            /></div> :
                            <form onSubmit={handleSubmit}>
                                <Link to="/"><img src={logo} className="mb-4 d-block mx-auto" alt="" style={{height:70}}/></Link>
                                <h5 className="mb-3">Daftar Menjadi Member Gondola</h5>
                            
                                <div className="form-floating mb-2">
                                    <input type="text" className="form-control" id="floatingInput" value={name} onChange={(e) => setName(e.target.value)} placeholder="Nama Lengkap"/>
                                    <label htmlFor="floatingInput">Nama Lengkap</label>
                                </div>

                                <div className="form-floating mb-2">
                                    <input type="email" className="form-control" id="floatingEmail" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="name@example.com"/>
                                    <label htmlFor="floatingEmail">Email</label>
                                </div>

                                <div className="form-floating mb-2">
                                    <input type="number" min={0} className="form-control" id="floatingInput" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="No. Telfon / No. WA"/>
                                    <label htmlFor="floatingInput">No. Telfon / No. WA</label>
                                </div>

                                <div className="form-floating mb-2">
                                    <input type="number" min={0} className="form-control" id="floatingInput" value={nik} onChange={(e) => setNIK(e.target.value)} placeholder="NIK (Nomor KTP)"/>
                                    <label htmlFor="floatingInput">NIK (Nomor KTP)</label>
                                </div>

                                <div className="form-floating mb-2">
                                    <input type="text" className="form-control" id="floatingInput" value={notiket} onChange={(e) => setNoTiket(e.target.value)} placeholder="Nomor Tiket"/>
                                    <label htmlFor="floatingInput">Nomor Tiket</label>
                                </div>

                                <button className="btn btn-primary w-100" type="submit">Daftar</button>

                                <div className="col-12 text-center mt-3">
                                    <span><span className="text-muted me-2">Already have an account ? </span> <Link to="/auth-login" className="text-dark fw-medium">Sign in</Link></span>
                                </div>
                            </form>}
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}