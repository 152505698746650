import React from "react";
import { Link } from "react-router-dom";

import bg3 from "../assect/images/bg/03.jpg"

import gedung from "../assect/images/dinding.jpg"
import loket from "../assect/images/loket.jpg"
import cabin from "../assect/images/cabingondola.jpg"
import dinding from "../assect/images/pilargedung.jpg"
import art3d from "../assect/images/3dart.jpg"

import Navbar from "../components/navbar";
import GetInTuch from "../components/getInTuch";
import AboutUs from "../components/about";

import {FiHexagon} from "../assect/icons/vander"
import Footer from "../components/footer";

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Pricing(){
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        lazyLoad: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    return(
        <>
        <Navbar navClass="defaultscroll sticky" logolight={true} menuClass = "navigation-menu nav-left nav-light"/>
        <section className="bg-half-170 d-table w-100" style={{ background: "linear-gradient(to right, #007bff, #00c6ff)"}}>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Mitra</h5>
                            <p className="fw-semibold mb-0 sub-heading text-white title-dark">Promosikan Brand Anda dengan cara unik dan menarik di Gondola Ancol, serta dapatkan berbagai keuntungan dengan menjadi mitra kami</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section pb-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="p-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
<section style={{ background: "#101820", borderTopRightRadius: "30px", borderTopLeftRadius: "30px",height: "800px"  }} className="rounded-t-r40 md:rounded-t-r64 -mt-10 md:-mt-16 lg:-mt-24 relative bg-gray-primary">
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "50px" }}>
        <div className="mb-13" style={{ marginBottom: "30px" }}>
            <h2 className="text-white" style={{ padding: "0 20px" }}>Jenis media iklan yang kami tawarkan:</h2>
        </div>
    </div>
    <div className="lg:container lg:mx-auto pb-20 lg:pb-35" style={{ paddingBottom: "140px", paddingLeft: "30px", paddingRight: "30px", display: "flex", flexDirection: "column", height: "100%", flex: "1" }}>
    <Slider {...settings}>
                <div className="swiper-slide pl-5 md:px-0 hidden md:block" style={{ flex: "0 0 20%", maxWidth: "50%", padding: "20px" }}>
                    <div style={{ background: "#101820" }} className="h-full flex flex-col rounded-r32 mx-auto p-7 xl:p-10">
                        <h3 style={{ color: "white", textAlign: "center" }} className="font-maisonExtendedBold text-size_12x leading-height_32x mb-3 text-white">Dinding Gedung</h3>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
                            <img src={dinding} alt="bordered image" style={{ borderRadius: "10px", width: "100%", height: "auto", maxWidth: "20rem", maxHeight: "22rem", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }} />
                        </div>
                        <div className="mt-auto">
                            <div style={{marginTop: "10px", backgroundColor: "#0082D7",  maxWidth: "20rem", maxHeight: "22rem",padding: "20px", borderRadius: "10px" }}>
                                <p style={{ color:'white', marginTop: "10px", textAlign:'left'}} className="mb-6">Merupakan akses utama para pengunjung untuk menuju ke cabin gondola</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="swiper-slide pl-5 md:px-0 hidden md:block" style={{ flex: "0 0 20%", maxWidth: "50%", padding: "20px" }}>
                    <div style={{ background: "#101820" }} className="h-full flex flex-col rounded-r32 mx-auto p-7 xl:p-10">
                        <h3 style={{ color: "white", textAlign: "center" }} className="font-maisonExtendedBold text-size_12x leading-height_32x mb-3 text-white">Loket</h3>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
                            <img src={loket} alt="bordered image" style={{ borderRadius: "10px", width: "100%", height: "auto", maxWidth: "20rem", maxHeight: "22rem", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }} />
                        </div>
                        <div className="mt-auto">
                            <div style={{marginTop: "10px", backgroundColor: "#0082D7",  maxWidth: "20rem", maxHeight: "22rem",padding: "20px", borderRadius: "10px" }}>
                                <p style={{ color:'white', marginTop: "10px", textAlign:'left',  maxWidth: "20rem", maxHeight: "22rem"}} className="mb-6">Merupakan akses utama pengunjung offline/online untuk mendapatkan tiket, berada di titik depan area Ancol</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="swiper-slide pl-5 md:px-0 hidden md:block" style={{ flex: "0 0 20%", maxWidth: "50%",  padding: "20px" }}>
                    <div style={{ background: "#101820" }} className="h-full flex flex-col rounded-r32 mx-auto p-7 xl:p-10">
                        <h3 style={{ color: "white", textAlign: "center" }} className="font-maisonExtendedBold text-size_12x leading-height_32x mb-3 text-white">Cabin Gondola</h3>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
                            <img src={cabin} alt="bordered image" style={{ borderRadius: "10px", width: "100%", height: "auto", maxWidth: "20rem", maxHeight: "22rem", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }} />
                        </div>
                        <div className="mt-auto">
                            <div style={{marginTop: "10px", backgroundColor: "#0082D7",  maxWidth: "20rem", maxHeight: "22rem",padding: "20px", borderRadius: "10px" }}>
                                <p style={{ color:'white', marginTop: "10px", textAlign:'left',  maxWidth: "20rem", maxHeight: "22rem"}} className="mb-6">Melewati titik strategis keramaian pengunjung Ancol, brand akan terlihat oleh seluruh pengunjung Ancol</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="swiper-slide pl-5 md:px-0 hidden md:block" style={{ flex: "0 0 20%", maxWidth: "50%",  padding: "20px"  }}>
                    <div style={{ background: "#101820" }} className="h-full flex flex-col rounded-r32 mx-auto p-7 xl:p-10">
                        <h3 style={{ color: "white", textAlign: "center" }} className="font-maisonExtendedBold text-size_12x leading-height_32x mb-3 text-white">Pilar Gedung</h3>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
                            <img src={gedung} alt="bordered image" style={{ borderRadius: "10px", width: "100%", height: "auto", maxWidth: "20rem", maxHeight: "22rem", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }} />
                        </div>
                        <div className="mt-auto">
                            <div style={{marginTop: "10px", backgroundColor: "#0082D7",maxWidth: "20rem", maxHeight: "30rem", borderRadius:"20px", padding: "20px" }}>
                                <p style={{ color:'white', marginTop: "10px", textAlign:'left' }} className="mb-6">Merupakan spot yang dilewati seluruh cabin gondola dan para pengunjung</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="swiper-slide pl-5 md:px-0 hidden md:block" style={{ flex: "0 0 20%", maxWidth: "50%",  padding: "20px"  }}>
                    <div style={{ background: "#101820" }} className="h-full flex flex-col rounded-r32 mx-auto p-7 xl:p-10">
                        <h3 style={{ color: "white", textAlign: "center" }} className="font-maisonExtendedBold text-size_12x leading-height_32x mb-3 text-white">3D Trick Art</h3>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
                            <img src={art3d} alt="bordered image" style={{ borderRadius: "10px", width: "100%", height: "auto", maxWidth: "20rem", maxHeight: "22rem", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }} />
                        </div>
                        <div className="mt-auto">
                            <div style={{marginTop: "10px", backgroundColor: "#0082D7",maxWidth: "20rem", maxHeight: "30rem", borderRadius:"20px", padding: "20px" }}>
                                <p style={{ color:'white', marginTop: "10px", textAlign:'left' }} className="mb-6">Merupakan salah satu wahana favorite di gondola dan juga jalur masuk untuk seluruh pengunjung gondola</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
    </div>
</section>
<section className="section pt-5">
            <div className="container mt-100 mt-60">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div className="section-title text-center">
                        <h4 className="title mb-3">Tertarik Promosikan Brand Anda di Gondola Ancol?</h4>
                        <p className="text-muted para-desc mb-0 mx-auto">Hubungi Tim kami melalui email</p>
                        <div className="mt-4 pt-2">
                            <Link to="mailto:customer.service@gondola-ancol.com" className="btn btn-pills btn-primary">customer.service@gondola-ancol.com</Link>
                        </div>
                    </div>
                    <div className="section-title text-center" style={{marginTop:"20px"}}>
                        <p className="text-muted para-desc mb-0 mx-auto">atau melalui Whatsapp</p>
                        <div className="mt-4 pt-2">
                            <Link to="https://api.whatsapp.com/send?phone=6285281672907&text=Halo%20Gondola%20Ancol" className="btn btn-pills btn-primary">085281672907</Link>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
        <Footer/>
        </>
    )
}