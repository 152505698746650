import React from "react";
import { Link } from "react-router-dom";
import { propertyData } from "../data/data";
import {FiHome, FiHeart, FiCamera} from '../assect/icons/vander'

import axios from 'axios'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export default function FuaturedProperties(){

    const [propertyData, setPropertyDataData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true)
        axios.get('https://api-v1.gondola-ancol.com/api/public/posts_home').then((response) => {
            setPropertyDataData(response.data.data);
            setLoading(false)
            console.log('response',response.data.data);
        });
      }, []);

    return(
        <>
        <div className="row justify-content-center">
            <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                    <h4 className="title mb-3">Promo Spesial</h4>
                    <p className="text-muted para-desc mb-0 mx-auto">Temukan berbagai promo menarik di Gondola Ancol di sini.</p>
                </div>
            </div>
        </div>

        <div className="row g-4">
            {loading == true?  (
            <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                <p>
                <Skeleton height={200} />
                </p>
                </SkeletonTheme>
                ) : (
                propertyData.slice(0,6).map((item, index) => {
                    return(
                        <div className="col-lg-4 col-md-6 col-12" key={index}>
                            <div className="card property border-0 shadow position-relative overflow-hidden rounded-3">
                                <div
                                    className="property-image position-relative overflow-hidden shadow"
                                    style={{
                                        backgroundImage: `url(${item.image})`,
                                        height: '100%',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                        transition: 'transform 0.3s ease',
                                        transform: 'scale(1)',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.target.style.transform = 'scale(1.1)';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.transform = 'scale(1)';
                                    }}
                                >
                                    <div
                                        className="image-overlay"
                                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', opacity: 0.7, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                    ></div>
                                    {/* Add this div for overlay */}
                                    <a
                                        href={`/promo-detail/${item.slug}`}
                                        className="property-content"
                                        style={{
                                            height: '300px',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            position: 'relative', // Add this line
                                            zIndex: 1, // Add this line
                                        }}
                                    >
                                        <div className="image-container" style={{ height: '100%' }}>
                                            <div
                                                className="text-overlay"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '100%',
                                                    color: 'white'
                                                }}
                                            >
                                                <span
                                                    className="title fs-5 text-white fw-bold"
                                                    style={{ fontWeight: 'bold', padding: '20px' }}
                                                >
                                                    {item.title}
                                                </span>
                                            </div>
                                            <ul className="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                                <li className="list-inline-item mb-0">
                                                    <span className="text-muted"></span>
                                                    <p className="fw-medium mb-0"></p>
                                                </li>
                                                <li className="list-inline-item mb-0 text-muted">
                                                    <span className="text-muted"></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                })

            )}

            <div className="col-12 mt-4 pt-2">
                {/* <div className="text-center">
                    <Link to="/grid" className="mt-3 fs-6 text-primary">Lihat Promo lebih <i className="mdi mdi-arrow-right align-middle"></i></Link>
                </div> */}
            </div>
        </div>
        </>
    )
}