import React, { useState } from "react";
import { Link } from "react-router-dom";

import heroImg from "../../assect/images/hero.jpg"
import image1 from '../../assect/images/1.jpg'
import logoIcon from '../../assect/images/logo-icon.png'
import background1 from '../../assect/images/bg/1.png'
import background2 from '../../assect/images/bg/2.png'
import background3 from '../../assect/images/bg/3.png'


import cabin from '../../assect/images/cabin.jpg'
import skydining from '../../assect/images/skydining.jpg'
import cable from '../../assect/images/cable.jpg'
import art from '../../assect/images/3dart.jpg'

import Navbar from "../../components/navbar";
import Categories from "../../components/categories"
import FeaturedProperties from "../../components/featuredProperties"
import AboutUs from "../../components/about";
import ClientOne from "../../components/clientOne";
import Broker from "../../components/broker";
import GetInTuch from "../../components/getInTuch";
import FooterTopImage from "../../components/FoterTopImage";
import Footer from "../../components/footer";
import Banners from "../../components/banners";

import { TypeAnimation } from 'react-type-animation';
import Select from 'react-select'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {FiHexagon} from "../../assect/icons/vander"

import {FiDollarSign, FiHome,FiSearch} from "../../assect/icons/vander"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

import axios from 'axios'

export default function Index(){

    const [activeIndex, setActiveIndex] = useState(0)
    const [sliderBanner, setSliderBanner] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true)
        axios.get('https://api-v1.gondola-ancol.com/api/public/sliders').then((response) => {
            setSliderBanner(response.data.data);
            setLoading(false)
            console.log('response',response.data.data);
        });
      }, []);

    const settings = {
        dots: true,
        autoplay: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const pricingData = [
        {
            icon:'mdi mdi-shield-star-outline',
            title:'Cable Car',
            time:'19',
            features: ['Kabin standar yang dimiliki oleh Gondola Ancol dengan keamanan yang terjamin, sehingga penumpang dapat melihat pemandangan dengan nyaman.'],
            btnClass:'btn btn-outline-primary w-100',
            img: cable
        },
        {
            icon:'mdi mdi-shield-star-outline',
            title:'Cabin Adrenaline',
            time:'19',
            features: ['Sesuai dengan nama nya, cabin adrenaline merupakan cabin yang ditawarkan oleh Gondola bagi penumpang yang ingin memiliki pengalaman yang berbeda dengan menaiki cabin yang cenderung terbuka untuk melihat pemandangan dari atas'],
            btnClass:'btn btn-outline-primary w-100',
            img: cabin
        },
        {
            icon:'mdi mdi-shield-star-outline',
            title:'3D Tric Art',
            time:'19',
            features: ['Selain menaiki kereta gantung/gondola, pengunjung dapat menikmati wahana lainnya, yaitu 3D ART. Sebuah tempat yang menampilkan karya seni 3 dimensi yang menakjubkan. Pengunjung dapat berinteraksi dengan lukisan dan menciptakan optik yang menarik dalam foto-foto mereka.'],
            btnClass:'btn btn-outline-primary w-100',
            img: art
        },
        {
            icon:'mdi mdi-shield-star-outline',
            title:'Sky Dining',
            time:'19',
            features: ['Pengalaman bersantap unik yang menggabungkan sensasi naik gondola dengan suasana mewah di dalam cabin yang dapat dilakukan bersama dengan pasangan atau dengan grup'],
            btnClass:'btn btn-outline-primary w-100',
            img: skydining
        },
    ]

    return(
        <>
        <Navbar navClass="defaultscroll sticky" menuClass = "navigation-menu nav-left"/>
        <section className="position-relative mt-5 pt-4">
            <div className="container-fluid px-md-4 px-2 mt-2">
            
            <div className="card property border-0 shadow position-relative overflow-hidden rounded-3">
                <div className="property-image position-relative overflow-hidden shadow">
                        <Slider {...settings}>
                        {loading == true?  (
                            <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                                <p>
                                <Skeleton height={200} />
                                </p>
                                </SkeletonTheme>
                                ) : (
                                sliderBanner.map((item, index) => {
                                return(
                                <div key={item.image}>
                                    <img src={item.image} alt="Gondola Ancol"/>
                                </div>
                                )
                            })
                        )}
                        </Slider>
                </div>
            </div>
            </div>
        </section>
        <section className="section pt-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 mt-sm-0 pt-sm-0">
                        <div className="features-absolute">
                            <ul className="nav nav-pills bg-white shadow border-bottom p-3 flex-row d-md-inline-flex nav-justified mb-0 rounded-top-3 position-relative overflow-hidden" id="pills-tab" role="tablist" style={{height:'50px'}}>
                                <li className="nav-item m-1">
                                    <p style={{fontWeight:'bold'}}>
                                        Beli Tiket Sekarang
                                    </p>
                                </li>
                            </ul>
                            
                            <div className="tab-content bg-white rounded-bottom-3 rounded-end-3 sm-rounded-0 shadow">
                                <div className="card border-0 active">
                                        <form className="card-body text-start">
                                            <div className="registration-form text-dark text-start">
                                                <div className="row g-lg-0">
                                                    <div className="col-lg-3 col-md-6 col-12" style={{marginRight:10,marginBottom:10}}>
                                                        <a href="/tiket" style={{color:'white',backgroundColor:'#FA591D'}} className="btn searchbtn w-100">Reguler</a>
                                                    </div>

                                                    <div className="col-lg-3 col-md-6 col-12">
                                                        <a href="/tiket-skydining" className="btn btn-primary searchbtn w-100">Skydining</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <Banners/>
            </div>
            
            <div className="container mt-100 mt-60">
                <FeaturedProperties/>
            </div>

             <div className="container mt-100 mt-60">
                <Categories/>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="row g-3 align-items-center">
                            <div className="col-lg-7 col-6">
                                <img src={heroImg} className="img-fluid rounded-3" alt="" title="gondola ancol"/>
                            </div>
                            
                            <div className="col-lg-5 col-6">
                                <div className="row g-3">
                                    <div className="col-lg-12 col-md-12">
                                        <img src={image1} className="img-fluid rounded-3" alt="" title="gondola ancol"/>
                                    </div>
    
                                    {/* <div className="col-lg-12 col-md-12">
                                        <img src={logoIcon} className="img-fluid" alt="" title="gondola ancol"/>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="section-title ms-lg-5">
                            <h6 className="text-primary fw-medium mb-2">Tentang Gondola Ancol</h6>
                            <h4 className="title mb-3">Kereta gantung pertama <br/> di Indonesia</h4>
                            <p className="text-muted para-desc mb-0">Sarana rekreasi kereta gantung pertama di Indonesia yang menggunakan komputerisasi tekhnologi tinggi dengan sistem kontrol dan keamanan tercanggih.

                            Terletak di dalam area rekreasi Taman Impian Jaya Ancol.

                            Pertama kali beroperasi pada tanggal 20 Desember 2003 dan diresmikan oleh Gubernur DKI Jakarta Bapak Sutiyoso. Sampai saat ini Gondola Ancol melayani sekitar 4,7 juta orang pengunjung.

                            Tahun 2015, Gondola Ancol melalukan pemeliharaan dengan melakukan pemotongan rope/kabel/seling Gondola.</p>
                        
                            <div className="mt-4">
                                <Link to="/aboutus" className="btn btn-pills btn-primary">Baca lebih <i className="mdi mdi-arrow-right align-middle"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <AboutUs/>
            </div>

            {/* <div className="container mt-100 mt-60">
               <ClientOne/>
            </div>

            <div className="container mt-100 mt-60">
                <Broker/>
            </div> */}
            <div className="row justify-content-center"  style={{ marginTop: '30px' }}>
                <div className="col">
                    <div className="section-title text-center mb-4 pb-2">
                        <h4 className="title mb-3">Variasi Wahana</h4>
                    </div>
                </div>
            </div>
            <div className="row g-4 justify-content-center" style={{ padding: '30px' }}>
                {pricingData.map((item, index) => {
                    return (
                        <div className="col-lg-4 col-md-6" key={index} style={{ display: 'flex', flexDirection: 'column', height: '100%', flex: '1' }}>
                            <div className="card rounded-3 shadow h-100" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div className="p-4 border-bottom text-center" style={{ backgroundImage: `url("${item.img}")`, height: '200px',borderTopLeftRadius: 10 ,borderTopRightRadius: 10, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat',marginBottom:10 }}>
                                    <div className="position-relative features text-center mx-lg-4 px-md-1" style={{ height: '100%' }}>
                                        <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center" style={{ height: '100%' }}>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-4 d-flex flex-column justify-content-between" style={{ height: '300px', backgroundColor: index % 2 === 0 ? '#fdce00' : '#2a5aa4', borderBottomLeftRadius: 10 ,borderBottomRightRadius: 10 }}>
                                    <h5 style={{ fontSize: 'clamp(16px, 5vw, 24px)', color: index % 2 === 0 ? 'black' : 'white' }}>{item.title}</h5>
                                    <ul className="list-unstyled flex-grow-1" style={{ overflow: 'hidden' }}>
                                        {item.features.map((el, i) => {
                                            const fontSize = `clamp(12px, ${Math.floor(3 * (300 / item.features.length))}px, 16px)`;
                                            return (
                                                <li className="text-muted mb-0 d-flex align-items-center" key={i}>
                                                    <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ height: '100%', display: 'flex', alignItems: 'center', fontSize, padding: '5px', color: index % 2 === 0 ? 'black' : 'white' }}>
                                                            {el}
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="container mt-100 mt-60">
                <GetInTuch/>
            </div>
        </section>
        <FooterTopImage/>
        <Footer/>
        </>
    )
}