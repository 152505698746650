import React from "react";
import { Link } from "react-router-dom";
import stasiunA from "../assect/images/stasiuna.jpg"
import stasiunC from "../assect/images/stasiunc.jpg"

import {FiHexagon, FiHome, FiBriefcase, FiKey} from '../assect/icons/vander'

export default function AboutUs(){
    const aboutData = [
        {
            icon: FiHome,
            title: 'Kendaraan Pribadi',
            desc: [{' Pastikan sudah mengetahui rute terbaik dan mengehindari kemacetan menuju ke Ancol': [],
                ' Pilihan stasiun Gondola tempat naik/turun pengunjung': []
            }]
        },
        {
            icon: FiHome,
            title: 'Kendaraan Umum',
            desc: [{
                ' Kereta Rel Listrik (KRL)': [
                    'Turun di Stasiun Kota, kemudian naik angkutan umum ke Ancol',
                ],
                ' Mass Rapid Transit (MRT)': [
                     'Naik MRT hingga stasiun Dukuh Atas/sudirman. Lalu lanjut menggunakan KRL sampai di stasiun Ancol. Setelah itu menggunakan ojek sampai pintu Ancol lalu dapat menaiki fasilitas Ancol yaitu Bus Wara-Wiri (free)',
                ],
                ' TransJakarta': [
                    'Naik rute TransJakarta yang melewati Halte Ancol. Setelah itu dapat menaiki fasilitas Ancol yaitu Bus Wara-Wiri (free)',
                ],
            }]
        },
    ]
    const [activeIndex, setActiveIndex] = React.useState(0);

    const handleTabClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
            {/* <div className="row justify-content-center">
                <div className="col">
                    <div className="section-title text-center mb-4 pb-2">
                        <h4 className="title mb-3">Gondola Ancol memiliki 3 stasiun</h4>
                        <p className="text-muted para-desc mb-0 mx-auto">Tersedia juga restoran cepat saji CFC di stasiun A dan C. Dari ketiga stasiun tersebut hanya 2 lokasi yang dapat digunakan untuk naik/turun pengunjung , yaitu stasiun A dan C</p>
                    </div>
                </div>
            </div>

            <div className="row g-4">
                    <div className="col-md-4">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                            <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                                <FiHexagon className="hexagon"/>
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <img src={stasiunA} className="img-fluid" alt="Stasiun A" style={{borderRadius:'100%',border: '10 solid #ddd'}}/>
                                </div>
                            </div>

                            <div className="mt-4">
                                <Link to="#" className="fw-medium title text-dark fs-5">Stasiun A</Link>
                                <p className="text-muted mt-3 mb-0">Stasiun A Pantai Festival, letaknya di belakang Dunia Fantasi.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                            <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                                <FiHexagon className="hexagon"/>
                            </div>

                            <div className="mt-4">
                                <Link to="#" className="fw-medium title text-dark fs-5">Stasiun B</Link>
                                <p className="text-muted mt-3 mb-0">Terletak di sekitar Putri Duyung.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                            <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                                <FiHexagon className="hexagon"/>
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <img src={stasiunC} className="img-fluid" alt="Stasiun A" style={{borderRadius:'100%',border: '10 solid #ddd'}}/>
                                </div>
                            </div>

                            <div className="mt-4">
                                <Link to="#" className="fw-medium title text-dark fs-5">Stasiun C</Link>
                                <p className="text-muted mt-3 mb-0">Stasiun C Teater mobil, letaknya disekitar Atlantis Water Adventure, Gelanggang Samudra, dan SeaWorld.</p>
                            </div>
                        </div>
                    </div>
            </div> */}

            <div className="row justify-content-center">
                <div className="col">
                    <div className="section-title text-center mb-4 pb-2">
                        <h4 className="title mb-3">Akses Menuju Gondola Ancol</h4>
                    </div>
                </div>
            </div>
            
            <ul className="nav nav-pills justify-content-center mb-4" id="pills-tab" role="tablist" style={{
                backgroundColor: 'transparent'
            }}>
                <li className="nav-item" role="presentation">
                    <Link 
                        className={`nav-link ${0 === activeIndex ? 'active' : ''}`} 
                        to={`#lokasi`} 
                        role="tab" 
                        aria-controls={`lokasi`} 
                        aria-selected={0 === activeIndex ? 'true' : 'false'} 
                        onClick={() => handleTabClick(0)}
                        style={{
                            color: 0 === activeIndex ? '#fff' : '#000',
                            backgroundColor: 0 === activeIndex ? '#007bff' : '#fff',
                            borderRadius: '5px',
                            padding: '10px 20px',
                            margin: '0 5px',
                            textDecoration: 'none',
                            transition: 'all 0.3s ease'
                        }}
                    >
                        Lokasi
                    </Link>
                </li>
                <li className="nav-item" role="presentation">
                    <Link 
                        className={`nav-link ${1 === activeIndex ? 'active' : ''}`} 
                        to={`#lokasi`} 
                        role="tab" 
                        aria-controls={`lokasi`} 
                        aria-selected={1 === activeIndex ? 'true' : 'false'} 
                        onClick={() => handleTabClick(1)}
                        style={{
                            color: 1 === activeIndex ? '#fff' : '#000',
                            backgroundColor: 1 === activeIndex ? '#007bff' : '#fff',
                            borderRadius: '5px',
                            padding: '10px 20px',
                            margin: '0 5px',
                            textDecoration: 'none',
                            transition: 'all 0.3s ease'
                        }}
                    >
                        Kendaraan Pribadi
                    </Link>
                </li>
                <li className="nav-item" role="presentation">
                    <Link 
                        className={`nav-link ${2 === activeIndex ? 'active' : ''}`} 
                        to={`#lokasi`} 
                        role="tab" 
                        aria-controls={`lokasi`} 
                        aria-selected={2 === activeIndex ? 'true' : 'false'} 
                        onClick={() => handleTabClick(2)}
                        style={{
                            color: 2 === activeIndex ? '#fff' : '#000',
                            backgroundColor: 2 === activeIndex ? '#007bff' : '#fff',
                            borderRadius: '5px',
                            padding: '10px 20px',
                            margin: '0 5px',
                            textDecoration: 'none',
                            transition: 'all 0.3s ease'
                        }}
                    >
                        Kendaraan Umum
                    </Link>
                </li>
                {/* {aboutData.map((item, index) => (
                    <li className="nav-item" role="presentation" key={index + 2}>
                        <Link 
                            className={`nav-link ${index + 2 === activeIndex ? 'active' : ''}`} 
                            to={`#${item.title}`} 
                            role="tab" 
                            aria-controls={`${item.title}`} 
                            aria-selected={index + 2 === activeIndex ? 'true' : 'false'} 
                            onClick={() => handleTabClick(index + 2)}
                            style={{
                                color: index + 2 === activeIndex ? '#fff' : '#000',
                                backgroundColor: index + 2 === activeIndex ? '#007bff' : '#fff',
                                borderRadius: '5px',
                                padding: '10px 20px',
                                margin: '0 5px',
                                textDecoration: 'none',
                                transition: 'all 0.3s ease'
                            }}
                        >
                            {item.title}
                        </Link>
                    </li>
                ))} */}
            </ul>

    <div className="row g-4">
    <div className="col-md-12">
    <div className="tab-content mt-4">
        <div className={`tab-pane fade ${1 === activeIndex ? 'show active' : ''}`} id={`Kendaraan Pribadi`} role="tabpanel">
            <h5 className="title mb-3">Kendaraan Pribadi</h5>
            <div className="section-title text-justify align-items-justify mb-4 pb-2">
                <ul>
                    <li><b>Pastikan sudah mengetahui rute terbaik dan mengehindari kemacetan menuju ke Ancol</b></li>
                    <li><b>Pilihan stasiun Gondola tempat naik/turun pengunjung</b></li>
                </ul>
            </div>

            {/* <ul className="text-muted mt-3 mb-0" style={{ listStyleType: 'none', paddingLeft: '0', display: 'flex', flexDirection: 'column' }}>
                <li style={{ marginBottom: '5px', position: 'relative' }}>
                    <span style={{ position: 'absolute', left: '-15px' }}>&#10148; </span>
                    Pastikan sudah mengetahui rute terbaik dan mengehindari kemacetan menuju ke Ancol
                </li>
                <li style={{ marginBottom: '5px', position: 'relative' }}>
                    <span style={{ position: 'absolute', left: '-15px' }}>&#10148; </span>
                    Pilihan stasiun Gondola tempat naik/turun pengunjung
                </li>
            </ul> */}
        </div>
        <div className={`tab-pane fade ${2 === activeIndex ? 'show active' : ''}`} id={`Kendaraan Umum`} role="tabpanel">
            <h5 className="title mb-3">Kendaraan Umum</h5>
            <div className="section-title text-justify align-items-justify mb-4 pb-2">
            <ul>
                <li>
                    <b>
                    Kereta Rel Listrik (KRL)</b>
                    <ul>
                        <li>Turun di Stasiun Kota, kemudian naik angkutan umum ke Ancol</li>
                    </ul>
                </li>
                <li>
                    <b>
                    Mass Rapid Transit (MRT)</b>
                    <ul>
                        <li>Naik MRT hingga stasiun Dukuh Atas/sudirman. Lalu lanjut menggunakan KRL sampai di stasiun Ancol. Setelah itu menggunakan ojek sampai pintu Ancol lalu dapat menaiki fasilitas Ancol yaitu Bus Wara-Wiri (free)</li>
                    </ul>
                </li>
                <li>
                    <b>
                    TransJakarta</b>
                    <ul>
                        <li>Naik rute TransJakarta yang melewati Halte Ancol. Setelah itu dapat menaiki fasilitas Ancol yaitu Bus Wara-Wiri (free)</li>
                    </ul>
                </li>
            </ul>
            </div>
        </div>
        <div className={`tab-pane fade ${0 === activeIndex ? 'show active' : ''}`} id={`Lokasi`} role="tabpanel">
            <div className="row justify-content-center">
                <div className="col">
                    <div className="section-title text-center mb-4 pb-2">
                        <h4 className="title mb-3">Gondola Terletak di Area Rekreasi Taman Impian Jaya Ancol</h4>
                        <h4 className="title mb-3">dan memiliki 3 stasiun yaitu:</h4>
                    </div>
                </div>
            </div>

            <div className="row g-4">
                    <div className="col-md-4">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                            <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                                <FiHexagon className="hexagon"/>
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <img src={stasiunA} className="img-fluid" alt="Stasiun A" style={{borderRadius:'100%',border: '10 solid #ddd'}}/>
                                </div>
                            </div>

                            <div className="mt-4">
                                <Link to="#" className="fw-medium title text-dark fs-5">Stasiun A</Link>
                                <p className="text-muted mt-3 mb-0">Stasiun A Pantai Festival, letaknya di belakang Dunia Fantasi.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                            <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                                <FiHexagon className="hexagon"/>
                            </div>

                            <div className="mt-4">
                                <Link to="#" className="fw-medium title text-dark fs-5">Stasiun B</Link>
                                <p className="text-muted mt-3 mb-0">Terletak di sekitar Putri Duyung.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="position-relative features text-center mx-lg-4 px-md-1">
                            <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                                <FiHexagon className="hexagon"/>
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <img src={stasiunC} className="img-fluid" alt="Stasiun A" style={{borderRadius:'100%',border: '10 solid #ddd'}}/>
                                </div>
                            </div>

                            <div className="mt-4">
                                <Link to="#" className="fw-medium title text-dark fs-5">Stasiun C</Link>
                                <p className="text-muted mt-3 mb-0">Stasiun C Teater mobil, letaknya disekitar Atlantis Water Adventure, Gelanggang Samudra, dan SeaWorld.</p>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="row justify-content-center">
                <div className="col">
                    <div className="section-title text-center mb-4 pb-2">
                        <p className="para-desc mb-0 mx-auto" style={{fontWeight:'bold'}}>Dari ketiga stasiun tersebut, hanya 2 lokasi yang dapat digunakan untuk naik/turun pengunjung yaitu stasiun A dan C</p>

                    </div>
                </div>
            </div>
        </div>
    </div>

        {/* <div className="tab-content mt-4">
            {aboutData.map((item, index) => (
                <div className={`tab-pane fade ${index === activeIndex ? 'show active' : ''}`} id={`${item.title}`} role="tabpanel" key={index}>
                    <h5 className="title mb-3">{item.title}</h5>
                    <ul className="text-muted mt-3 mb-0" style={{ listStyleType: 'none', paddingLeft: '0', display: 'flex', flexDirection: 'column' }}>
                        {Object.entries(item.desc[0]).map(([key, value], descIndex) => (
                            <React.Fragment key={descIndex}>
                                <li style={{ marginBottom: '5px', position: 'relative' }}>
                                    <span style={{ position: 'absolute', left: '-15px' }}>&#10148; </span>
                                    {key}
                                </li>
                                {Array.isArray(value) && (
                                    <ul style={{ listStyleType: 'none', paddingLeft: '15px', marginBottom: '10px', display: 'flex', flexDirection: 'column' }}>
                                        {value.map((descItem, subDescIndex) => (
                                            <li key={subDescIndex}>  {descItem}</li>
                                        ))}
                                    </ul>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            ))}
        </div> */}
    </div>
</div>

        </>
    )
}