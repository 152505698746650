import React from "react";
import { Link } from "react-router-dom";

import logoLight from "../assect/images/logomobile.gif"
import logobrand from "../assect/images/brand.png"
import { FaTiktok } from "react-icons/fa";


import {FiShoppingCart, FiDribbble, FiLinkedin, FiFacebook, FiInstagram, FiTwitter, FiMail, FiMapPin, FiPhone} from "../assect/icons/vander"

export default function Footer(){
    return(
        <>
        
        <footer className="bg-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-py-60 footer-border">
                            <div className="row">
                                <div className="col-lg-5 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                    <Link to="#" className="logo-footer">
                                        <img src={logoLight} alt="" style={{height:70}}/>
                                    </Link>
                                    <p className="mt-4">Sarana rekreasi kereta gantung pertama di Indonesia yang menggunakan komputerisasi tekhnologi tinggi dengan sistem kontrol dan keamanan tercanggih.</p>
                                    <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                        <li className="list-inline-item"><Link to="https://www.tiktok.com/@gondolaancol" target="_blank" className="rounded-3"><FaTiktok  className="fea icon-sm align-middle"/></Link></li>
                                        <li className="list-inline-item"><Link to="https://www.instagram.com/gondolaancol/" target="_blank" className="rounded-3"><FiInstagram className="fea icon-sm align-middle"/></Link></li>
                                        <li className="list-inline-item"><Link to="mailto:customer.service@gondola-ancol.com" className="rounded-3"><FiMail className="fea icon-sm align-middle"/></Link></li>
                                    </ul>
                                    <img src={logobrand} alt="Logo" style={{ width: "100%" }} />

                                </div>
                                
                                <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Gondola Ancol</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right align-middle me-1"></i> Tentang Kami</Link></li>
                                        <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right align-middle me-1"></i> Tiket & Promo</Link></li>
                                        <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right align-middle me-1"></i> Acara</Link></li>
                                        <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right align-middle me-1"></i> Jelajah Gondola Ancol</Link></li>
                                        <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right align-middle me-1"></i> Berita</Link></li>
                                    </ul>
                                </div>
                                
                                {/* <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Usefull Links</h5>
                                    <ul className="list-unstyled footer-list mt-4">
                                        <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right align-middle me-1"></i> Terms of Services</Link></li>
                                        <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right align-middle me-1"></i> Privacy Policy</Link></li>
                                        <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right align-middle me-1"></i> Listing</Link></li>
                                        <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right align-middle me-1"></i> Contact us</Link></li>
                                    </ul>
                                </div> */}
            
                                <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <h5 className="footer-head">Kontak Kami</h5>

                                    <div className="d-flex mt-4">
                                        <FiMapPin className="fea icon-sm text-primary mt-1 me-3"/>
                                        <div className="">
                                            <p className="mb-2">Gondola Kereta Gantung Ancol<br/>Jl. Lodan Timur No. 7, Taman Impian Jaya Ancol, Jakarta 14430</p>
                                            <Link to="https://maps.app.goo.gl/hoe3f6Pba2hQz73c7" data-type="iframe" className="text-primary lightbox">View on Google map</Link>
                                        </div>
                                    </div>
        
                                    <div className="d-flex mt-4">
                                        <FiMail className="fea icon-sm text-primary mt-1 me-3"/>
                                        <Link to="mailto:customer.service@gondola-ancol.com" className="text-foot">customer.service@gondola-ancol.com</Link>
                                    </div>
                                    
                                    <div className="d-flex mt-4">
                                        <FiPhone className="fea icon-sm text-primary mt-1 me-3"/>
                                        <Link to="tel:+6216413429" className="text-foot">Tlp. (021) 641 3428 / 29  |  Fax. (021) 641 3447</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-py-30 footer-bar">
                <div className="container text-center">
                    <div className="row">
                        <div className="col">
                            <div className="text-center">
                                <p className="mb-0">© {new Date().getFullYear()} Gondola Ancol</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}