import React from "react";
import { Link, useParams } from "react-router-dom";

import bg3 from "../../assect/images/bg/03.jpg"
import image1 from "../../assect/images/property/single/1.jpg"
import image2 from "../../assect/images/property/single/2.jpg"
import image3 from "../../assect/images/property/single/3.jpg"
import image4 from "../../assect/images/property/single/4.jpg"
import image5 from "../../assect/images/property/single/5.jpg"

import Navbar from "../../components/navbar";
import ProprtySlider from "../../components/propertySlider";

import TinySlider from "tiny-slider-react";
import '../../../node_modules/tiny-slider/dist/tiny-slider.css';
import Footer from "../../components/footer";

import axios from 'axios'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import FeatureProperties from "../../components/featuredProperties";

export default function PropertyDetailsTwo(){

    const params = useParams()
    const id = params.id

    const images = [image1, image2, image3, image4, image5]
    const settings = {
        container: '.tiny-one-item',
        items: 1,
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
      };

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
    setLoading(true)
    axios.get('https://api-v1.gondola-ancol.com/api/public/posts/' + id).then((response) => {
        setData(response.data.data);
        setLoading(false)
        console.log('response',response.data.data);
    });
    }, []);

    return(
        <>
        <Navbar navClass="defaultscroll sticky" logolight={true} menuClass = "navigation-menu nav-left nav-light"/>
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg3})`}}>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">{data?.title ? data?.title : 
                            <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                            <p>
                            <Skeleton height={50} />
                            </p>
                            </SkeletonTheme>}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="tiny-one-item">
                            <TinySlider settings={settings}>
                                <div className="tiny-slide">
                                    <div className="m-md-2">
                                        {data?.image ? <img src={data.image} className="shadow rounded-3 img-fluid" alt=""/>: 
                                        <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                                        <p>
                                        <Skeleton height={50} />
                                        </p>
                                        </SkeletonTheme>}
                                    </div>
                                </div>
                            </TinySlider>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                        <div className="features-absolute subscribe-search">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-md-9">
                                    <div className="rounded-3 shadow bg-white sticky-bar p-4">
                                        {/* <h5 className="mb-3">Price:</h5> */}
            
                                        {/* <div className="d-flex align-items-center justify-content-between">
                                            <h5 className="mb-0">$ 45,231</h5>
                                            <span className="badge bg-primary">For Sale</span>
                                        </div>
            
                                        <div className="">
                                            <div className="d-flex align-items-center justify-content-between mt-2">
                                                <span className="small text-muted">Days on Towntor</span>
                                                <span className="small">124 Days</span>
                                            </div>
            
                                            <div className="d-flex align-items-center justify-content-between mt-2">
                                                <span className="small text-muted">Price per sq ft</span>
                                                <span className="small">$ 186</span>
                                            </div>
            
                                            <div className="d-flex align-items-center justify-content-between mt-2">
                                                <span className="small text-muted">Monthly Payment (estimate)</span>
                                                <span className="small">$ 1497/Monthly</span>
                                            </div>
                                        </div> */}
            
                                        <div className="d-flex mt-3">
                                            <a href="/tiket" className="btn btn-primary w-100 me-2">Website</a>
                                            <a to="https://www.traveloka.com/id-id/activities/indonesia/product/gondola-ancol-tickets-2001916951457" className="btn btn-primary w-100">Traveloka</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        {data?.content ?  <p className="text-muted" dangerouslySetInnerHTML={{ __html: data.content }}></p>: 
                            <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                            <p>
                            <Skeleton height={50} />
                            </p>
                            </SkeletonTheme>}
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                {/* <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-3">Promo Terkait</h4>
                            <p className="text-muted para-desc mb-0 mx-auto">Sarana rekreasi kereta gantung pertama di Indonesia yang menggunakan komputerisasi tekhnologi tinggi dengan sistem kontrol dan keamanan tercanggih.</p>
                        </div>
                    </div>
                </div> */}

                <FeatureProperties/>
            </div>
        </section>
        <Footer/>
        </>
    )
}