import React,{useState} from "react";
import { Link,useParams } from "react-router-dom";

import image1 from '../../assect/images/property/single/1.jpg'
import image2 from '../../assect/images/property/single/2.jpg'
import image3 from '../../assect/images/property/single/3.jpg'
import image4 from '../../assect/images/property/single/4.jpg'
import image5 from '../../assect/images/property/single/5.jpg'

import Navbar from "../../components/navbar";
import FeatureProperties from "../../components/featuredProperties";

import { propertyData } from "../../data/data";

import Lightbox from 'react-18-image-lightbox'
import "../../../node_modules/react-18-image-lightbox/style.css"
import Footer from "../../components/footer";

import axios from 'axios'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export default function ProductDetails(){

    const params = useParams()
    const id = params.id
    // const data = propertyData.find((item) => item.id === parseInt(id))

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [open, setIsOpen] = useState(false);
    const images =[image1,image2,image3,image4,image5]

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
    axios.get('https://api-v1.gondola-ancol.com/api/public/products/' + id).then((response) => {
        setData(response.data.data);
        console.log('response',response.data.data);
    });
    }, []);

   const handleMovePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + images.length - 1) % images.length);
    };

    const handleMoveNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setIsOpen(true);
    };

    const currentImage = images[currentImageIndex];
    return(
        <>
        <Navbar navClass="defaultscroll sticky" menuClass = "navigation-menu nav-left"/>
        <section className="section mt-5 pt-4">
            <div className="container-fluid mt-2">
                <div className="row g-2">
                    <div className="col-md-12">
                        <Link to="#" className="lightbox" title="">
                            {data?.image ?  <img src={data.image} className="img-fluid rounded-3 shadow" alt=""/>: 
                            <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                            <p>
                            <Skeleton height={100} />
                            </p>
                            </SkeletonTheme>}
                        </Link>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row g-4">
                    <div className="col-lg-8 col-md-7 col-12">
                        <div className="section-title">
                            <h4 className="title mb-0">{data?.title ? data?.title : 
                            <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                            <p>
                            <Skeleton height={50} />
                            </p>
                            </SkeletonTheme>}</h4>
                            
                            {data?.content ?  <p className="text-muted" dangerouslySetInnerHTML={{ __html: data.content }}></p>: 
                            <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                            <p>
                            <Skeleton height={50} />
                            </p>
                            </SkeletonTheme>}
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-5 col-12">
                        <div className="rounded-3 shadow bg-white sticky-bar p-4">
                            {/* <h5 className="mb-3">Harga :</h5> */}

                            {/* <div className="d-flex align-items-center justify-content-between">
                                <h5 className="mb-0">IDR {data?.price ?  data.price: 
                            <SkeletonTheme baseColor="#F5F5F5" highlightColor="#444">
                            <p>
                            <Skeleton height={50} />
                            </p>
                            </SkeletonTheme>}</h5>
                            </div> */}


                            <h5 className="mb-3">Pesan Tiket Sekarang</h5>
                            <div className="d-flex mt-3">
                                <a href="/tiket" className="btn btn-primary w-100 me-2">Website</a>
                                <a href="https://www.traveloka.com/id-id/activities/indonesia/product/gondola-ancol-tickets-2001916951457" className="btn btn-primary w-100 me-2">Traveloka</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                {/* <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-3">Promo Terkait</h4>
                            <p className="text-muted para-desc mb-0 mx-auto">Dapatkan promo terkait Gondola Ancol.</p>
                        </div>
                    </div>
                </div> */}

                <FeatureProperties/>
            </div>
        </section>
        <Footer/>
        {open && (
                <Lightbox
                    mainSrc={currentImage}
                    prevSrc={images[(currentImageIndex + images.length - 1) % images.length]}
                    nextSrc={images[(currentImageIndex + 1) % images.length]}

                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
            )}
        </>
    )
}